import React from "react"
import Title from "../components/title"
import ComplaintsAndSuggestionsForm from "../components/complaints&suggestions-form"
import Header from "../components/blogHeader"
import SEO from "../components/seo"

import styles from "../styles/components/complaints&suggestions.module.scss"
import Footer from "../components/footer"

const ComplaintsAndSuggestions = () => (
  <>
    <Header />
    <SEO
      title="Lifetime Fitness Complaints And Suggestions page"
      description="Lifetime Fitness is more than just a gym - it's a caring family that's there to help you achieve your goals. Join our gym family today!"
    />
    <div className={styles.main} id="complaints&suggestions">
      <div className={styles.title}>
        <Title text="Complaints & Suggestions" />
      </div>
      <div>
        <ComplaintsAndSuggestionsForm />
      </div>
    </div>
    <Footer />
  </>
)

export default ComplaintsAndSuggestions
