import React, { Component } from "react"
import { navigate } from "gatsby-link"

// Styles
import styles from "../styles/components/complaints&suggestions-form.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ComplaintsAndSuggestionsForm extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    emailValid: false,
    feedbackType: "complaint",
    nameRequired: false,
    emailRequired: false,
  }

  handleChange = e => {
    const { name, value } = e.target

    if (name === "email") {
      this.validateEmail(value)
    }

    this.setState({ [name]: value }, () => {
      this.updateFieldRequirements()
    })
  }

  updateFieldRequirements = () => {
    const { feedbackType } = this.state
    const nameRequired = feedbackType === "suggestion"
    const emailRequired = feedbackType === "suggestion"
    const isButtonDisabled =
      feedbackType === "suggestion" && (!this.state.name || !this.state.email)

    this.setState({ nameRequired, emailRequired, isButtonDisabled })
  }

  handleSubmit = e => {
    e.preventDefault()

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "complaints-and-suggestions",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  validateEmail = email => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.setState({
      emailValid: re.test(String(email).toLowerCase()),
    })
  }

  render() {
    const { nameRequired, emailRequired, isButtonDisabled } = this.state

    return (
      <div className={styles.main}>
        <form
          className={styles.form}
          name="complaints-and-suggestions"
          method="post"
          action="/success/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input
            type="hidden"
            name="form-name"
            value="complaints-and-suggestions"
          />
          <label style={{ display: "none" }}>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>

          <div className={styles.dropdown}>
            <p>Do you want to make a Suggestion or Complaint?</p>
            <select
              name="feedbackType"
              onChange={this.handleChange}
              value={this.state.feedbackType}
              className={styles.select}
            >
              <option value="complaint">Complaint</option>
              <option value="suggestion">Suggestion</option>
            </select>
          </div>
          <input
            type="text"
            name="name"
            placeholder={nameRequired ? "Name" : "Name (Optional)"}
            onChange={this.handleChange}
            value={this.state.name}
          />
          <input
            type="email"
            name="email"
            placeholder={emailRequired ? "Email" : "Email (Optional)"}
            onChange={this.handleChange}
            value={this.state.email}
          />

          <textarea
            name="message"
            placeholder="Message"
            required
            onChange={this.handleChange}
            value={this.state.message}
          />
          <p>
            *The information provided in this form is confidential and will not
            be shared with anyone. We respect your privacy and are committed to
            protecting your personal information.
          </p>
          <button
            className={styles.btn}
            type="submit"
            disabled={!this.state.message || isButtonDisabled}
            style={
              !this.state.message || isButtonDisabled
                ? {
                    backgroundColor: "#484e51",
                    cursor: "default",
                    transform: "none",
                  }
                : { backgroundColor: "#fe5153" }
            }
          >
            Send
          </button>
        </form>
      </div>
    )
  }
}

export default ComplaintsAndSuggestionsForm
